
.new-try{
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 400px;
    height: 220px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: 1s;
    /* margin: 1vh 1vw 1vh 1vw; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    /* border-top: rgba(255, 255, 255, .4) 1px solid;
    border-left: rgba(255, 255, 255, .4) 1px solid;
    border-bottom: rgba(0, 0, 0, .4) 1px solid;
    border-right: rgba(0, 0, 0, .4) 1px solid; */
  }
  
  .new-try .layer {
    top: 0;
    left:0;
    position:absolute;
    /* width: 340px; */
    width: -webkit-fill-available;
    height:220px;
  }

  .new-try:hover .layer{
    background-color: rgba(0, 0, 0, .4);
    z-index: 99;
    transition: .3s;
  }
  
  .new-try:hover .picture-text{
    opacity: 1;
  }
  
  .new-try:active {
    opacity: 0;
  }
  
  .new-try .picture-text {
    color: white;
    position: relative;
    z-index: 999;
    margin: 10px;
    /* left: 0;
    right: 0; */
    top: 0;
    opacity: 0;
    overflow:hidden;
  }

  .new-try a {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 220px;
  }
  
  .new-try a img {
    width: 100%;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 0; */
  }