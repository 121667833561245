
body {
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;
  }
  
  .color-rect {
    position: relative;
      /* width: 70px; */
      height: auto;
      transition: 1s;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      color: white;
      background: black;
      margin: 3px;
  }
  
  .square-box{
    position: relative;
    display: flex;
    /* width: 10% !important; */
    min-height: 70px;
    height: 10vh;
    overflow: hidden;
    transition: 1s;
    /* margin: 1vh 1vw 1vh 1vw; */
    margin: 0;
  }
  .square-box:before{
      content: "";
      display: block;
      padding-top: 100%;
  }
  .square-content{
      position:  absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      color: white;
  }
  .square-content div {
     display: table;
     width: 100%;
     height: 100%;
  }
  .square-content span {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      color: white
  }
  
  
  .ACTIVE {
    border: 5px solid #31ff98; 
  }
  
  .unselectable {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  /* loading animation */
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  
  /* mobile screens */
  @media screen and (max-width: 600px) {  
    .navbar {
      min-height: 0 !important;
      background-image: url("../../../public/minipaintwithbobross.png") !important;
      background-size: cover !important;
    }
  }
  
  .navbar {
    background-image: url("../../../public/paintwithbobross.png");
    background-size: inherit;
    min-height: 80px;
    background-repeat: no-repeat; 
  }
    